<template>
  <div class="my-box">
    <div class="top-content">
      <Header :title="title" :back="true"/>
      <div class="property">
        <div class="property-title">昨日收益（USD）</div>
        <div class="box-num">+{{yesterday|formatFloat}}</div>
        <div class="flex-box tab-items right-border">
          <div>
            <div>总金额</div>
            <div>{{total|formatFloat}}</div>
          </div>
          <div>
            <div>累计收益</div>
            <div>{{income|formatFloat}}</div>
          </div>
          <div>
            <div>预期年化(%)</div>
            <div>+{{rate|formatFloat}}%</div>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div class="box-items" v-for="item in list" :key="item.id" @click="detail(item.id)">
          <div class="flex-box tab-title">
            <div>
              {{formatDate(item.date)}} 开始计息
            <span v-if="item.status=='end'">[已到期]</span>
            <span v-if="item.status=='called'">[已转出]</span>
            </div>
            <div>+{{item.yesterday}}</div>
          </div>
          <div class="flex-box tab-items">
            <div>
              <div>总金额</div>
              <div>{{item.worth|formatFloat}}</div>
            </div>
            <div>
              <div>累计收益</div>
              <div>{{item.income|formatFloat}}</div>
            </div>
            <div>
              <div>预期年化(%)</div>
              <div style="text-align:right">{{item.rate|formatFloat}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="finacing-footer">
      <div class="add-btn theme-bgcolor" @click="addTz">追加投资</div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return {
      title:'',
      type:this.$route.query.type,
      transition:false,
      activeName:'three',
      list:[],
      stagesList:[],
      total:'',
      income:'',
      rate:'',
      yesterday:''
     
    }
  },
  created(){
   
    this.getlist()
    this.myStages()
    
  },
  methods:{
     myStages(){
      this.$store.dispatch('transation/getUserAmount',{params:{code:'my',type:'termInvestments'}}).then(res => {
        this.stagesList = res.investment.investments.filter(item=>item.name==this.type)
        this.title = this.stagesList[0].title
        this.yesterday = this.stagesList[0].yesterday
        this.total = this.stagesList[0].callmoney
        this.income = this.stagesList[0].income
        this.rate = this.stagesList[0].currentRate
        
      })
    },
    getlist(){
      this.$store.dispatch('transation/getUserAmount',{params:{code:'my',type:'term',term:this.type}})
        .then((res)=>{
          this.list =res.term
        })
    },
    // 详情
    detail(id){
      this.$router.push({path:'investdetail',query:{key:id}})
    },
    // 追加投资
    addTz(){
      this.$router.push({name:'stages'})
    }
  }
}
</script>
<style lang="scss" scoped>
.my-box{
  .title{
    margin-bottom: 0px;
  }
}
.property{
  background: #ffffff;
  padding: 5% 4%;
  text-align: center;
  .property-title{
    font-size: 1rem;
    color: #333;
    margin-bottom:10px;
  }
}
.tab-box{
  background: #ffffff;
  padding: 1% 2.5%;
}
.tab-content{
  background: #ffffff;
  padding: .5% 2.5% 3%;
  .box-items{
    margin-bottom:2.5%
  }
}
.tab-title{
  justify-content:space-between;
  align-items: center;
  margin-bottom: 1rem;
  :nth-child(1){
    // font-size: 1.2rem;
    font-weight: 600;
    line-height: 1;
  }
  :nth-child(2){
    font-size: .8rem;
    line-height: 1;
  }
}
.tab-items{
  width: 100%;
  justify-content:space-between;
   div{
    :nth-child(1){
      font-size: .8rem;
      color:#666;
      margin-bottom:5px;
    }
    :nth-child(2){
      font-size: 1rem;
    }
}
}
.icon-up{
  text-align: center;
  i{
    transform: rotate(90deg);
    transition: transform 2s;
    -webkit-transition: transform 2s; /* Safari */
  }
  // i:hover{
  //   transform: rotate(270deg)
  // }
}
.defined-state{
  height: 0px;
  overflow: hidden;
  transition: height 2s;
  -webkit-transition: height 2s; /* Safari */
}
.defined-state.active{
  height: 130px;
}
.insure{
  font-size: .9rem;
  color: #666;
  margin:3% 0%;
}
.btn-box{
  text-align: center;
  .defined-btn{
    width: 95%;
    margin: 10px 15px;
  }
}
.right-border{
  width: 100%;
  margin-top:2rem;
  >div{
    width: 100%;
    border-right:1px solid #e4e4e4;
  }
  >:last-child{
    border-right:none;
  }
  
}
.finacing-footer{
  height: 3rem;
  border-top:1px solid #eee;
  .add-btn{
    border-radius:8px;
    width: 95%;
    margin: 2.5%;
    text-align: center;
    line-height: 2.5;
    color: #fff;
  }
}
</style>