var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-box" }, [
    _c(
      "div",
      { staticClass: "top-content" },
      [
        _c("Header", { attrs: { title: _vm.title, back: true } }),
        _c("div", { staticClass: "property" }, [
          _c("div", { staticClass: "property-title" }, [
            _vm._v("昨日收益（USD）"),
          ]),
          _c("div", { staticClass: "box-num" }, [
            _vm._v("+" + _vm._s(_vm._f("formatFloat")(_vm.yesterday))),
          ]),
          _c("div", { staticClass: "flex-box tab-items right-border" }, [
            _c("div", [
              _c("div", [_vm._v("总金额")]),
              _c("div", [_vm._v(_vm._s(_vm._f("formatFloat")(_vm.total)))]),
            ]),
            _c("div", [
              _c("div", [_vm._v("累计收益")]),
              _c("div", [_vm._v(_vm._s(_vm._f("formatFloat")(_vm.income)))]),
            ]),
            _c("div", [
              _c("div", [_vm._v("预期年化(%)")]),
              _c("div", [
                _vm._v("+" + _vm._s(_vm._f("formatFloat")(_vm.rate)) + "%"),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "tab-content" },
          _vm._l(_vm.list, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "box-items",
                on: {
                  click: function ($event) {
                    return _vm.detail(item.id)
                  },
                },
              },
              [
                _c("div", { staticClass: "flex-box tab-title" }, [
                  _c("div", [
                    _vm._v(
                      " " + _vm._s(_vm.formatDate(item.date)) + " 开始计息 "
                    ),
                    item.status == "end"
                      ? _c("span", [_vm._v("[已到期]")])
                      : _vm._e(),
                    item.status == "called"
                      ? _c("span", [_vm._v("[已转出]")])
                      : _vm._e(),
                  ]),
                  _c("div", [_vm._v("+" + _vm._s(item.yesterday))]),
                ]),
                _c("div", { staticClass: "flex-box tab-items" }, [
                  _c("div", [
                    _c("div", [_vm._v("总金额")]),
                    _c("div", [
                      _vm._v(_vm._s(_vm._f("formatFloat")(item.worth))),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", [_vm._v("累计收益")]),
                    _c("div", [
                      _vm._v(_vm._s(_vm._f("formatFloat")(item.income))),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", [_vm._v("预期年化(%)")]),
                    _c("div", { staticStyle: { "text-align": "right" } }, [
                      _vm._v(_vm._s(_vm._f("formatFloat")(item.rate))),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
    _c("div", { staticClass: "finacing-footer" }, [
      _c(
        "div",
        { staticClass: "add-btn theme-bgcolor", on: { click: _vm.addTz } },
        [_vm._v("追加投资")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }